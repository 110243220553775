import isFunction from 'lodash/isFunction'
import Config from '../Config'

export default function handleError<T extends Error>(error: T): T {
  const exceptionHandler = Config.get().exceptionHandler

  if (exceptionHandler && isFunction(exceptionHandler)) {
    exceptionHandler(error)
    return error
  } else {
    throw error as Error
  }
}
