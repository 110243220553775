/**
 * Implementation of Proof Key for Code Exchange algorithm
 * to send user to the sign in url with preparing a proof key
 * and saving it temporaly to validated after callback.
 */

import CryptoJS from 'crypto-js'
import SHA256 from 'crypto-js/sha256'
import OAuthError from './errors/OAuthError'
import StorageManager from './StorageManager'
import isPresent from './utils/isPresent'
import handleError from './utils/handleError'
import debugLog from './utils/debugLog'

/**
 * Thanks to @SEIAROTg on stackoverflow:
 * "Convert a 32bit integer into 4 bytes of data in javascript"
 * @param num The 32bit integer
 * @returns An ArrayBuffer representing 4 bytes of binary data
 */
export function toBytesInt32(num: number) {
  const arr = new ArrayBuffer(4) // an Int32 takes 4 bytes
  const view = new DataView(arr)
  view.setUint32(0, num, false) // byteOffset = 0; litteEndian = false
  return arr
}

/**
 * Creates an array of length `size` of random bytes
 * @param size
 * @returns Array of random ints (0 to 255)
 */
export function getRandomValues(size: number) {
  const randoms = CryptoJS.lib.WordArray.random(size)
  const randoms1byte: number[] = []

  randoms.words.forEach((word) => {
    const arr = toBytesInt32(word)
    const fourByteWord = new Uint8Array(arr)

    for (let i = 0; i < 4; i++) {
      randoms1byte.push(fourByteWord[i])
    }
  })

  return randoms1byte
}

/**
 * Generate cryptographically strong random string
 * @param size The desired length of the string
 * @returns The random string
 */
export function random(size: number) {
  let result = ''
  const mask = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~'
  const randomUints = getRandomValues(size)

  for (let i = 0; i < size; i++) {
    // cap the value of the randomIndex to mask.length - 1
    const randomIndex = randomUints[i] % mask.length
    result += mask[randomIndex]
  }
  return result
}

/**
 * Generate a PKCE challenge verifier
 * @param length Length of the verifier
 * @returns A random verifier `length` characters long
 */
export function generateVerifier(length: number): string {
  debugLog(['PkceHelper.generateVerifier', length])

  return random(length)
}

/**
 * Generate a PKCE code challenge from a code verifier
 * @param codeVerifier
 * @returns The base64 url encoded code challenge
 */
export function generateChallenge(codeVerifier: string) {
  debugLog(['PkceHelper.generateChallenge', codeVerifier])

  return SHA256(codeVerifier).toString(CryptoJS.enc.Base64url)
}

/**
 * Generate PKCE verifier and challenge.
 */
export function generateCredentials(
  length?: number,
): { verifier: string, challenge: string } {
  debugLog(['PkceHelper.generateCredentials', length])

  if (!length) length = 64

  if (length < 43 || length > 128) {
    handleError(new OAuthError(
      `Expected a length between 43 and 128. Received ${length}.`,
      'INCOMPLETE_CONFIGURATION'
    ))
    return { verifier: '', challenge: '' }
  }

  const verifier = generateVerifier(length)
  const challenge = generateChallenge(verifier)

  return { verifier, challenge }
}

/**
 * Generate PKCE verifier and challenge and save verifier to storage.
 * Optionally, you can define to save the challenge in store too for
 * debugging purposes.
 */
export async function generateAndPersistCredentials(options: {
  debug?: boolean,
} = {}): Promise<{
  verifier: string, challenge: string
}> {
  debugLog(['PkceHelper.generateAndPersistCredentials', options])

  const debug = options.debug === true
  const { verifier, challenge } = generateCredentials()

  await StorageManager.setPkceData(debug
    ? { verifier, debugChallenge: challenge }
    : { verifier }
  )

  return { verifier, challenge }
}

/**
 * Returns the (by #buildAuthSignInAuthorizationCodePkceData) persited verifier or null.
 * @return The verifier or null
 */
export async function getStoredCodeVerifier(): Promise<string | undefined> {
  const verifier = await StorageManager.getPkceVerifier()
  return isPresent(verifier) ? verifier : undefined
}

/**
 * Verify that a code_verifier produces the expected code challenge
 * @param codeVerifier
 * @param expectedChallenge The code challenge to verify
 * @returns True if challenges are equal. False otherwise.
 */
export function verifyChallenge(
  codeVerifier: string,
  expectedChallenge: string
) {
  debugLog(['PkceHelper.verifyChallenge', codeVerifier, expectedChallenge])

  const actualChallenge = generateChallenge(codeVerifier)
  return actualChallenge === expectedChallenge
}

/**
 * Deletes the store entry for verifier
 */
export async function resetData(): Promise<void> {
  await StorageManager.setPkceData({})
}

/**
 * PkceHelper
 */
const PkceHelper = {
  generateCredentials,
  generateAndPersistCredentials,
  getStoredCodeVerifier,
  resetData,
  verifyChallenge,
}
export default PkceHelper
