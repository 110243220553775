import type { OAuthErrorReason } from '../Types'

interface ErrorWithCaptureStackTrace extends Error {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  captureStackTrace(targetObject: object, constructorOpt?: Function): void;
}

/**
 * OAuthError which contains dedicated error codes
 * as "reason" attribute.
 */
class OAuthError extends Error {
  /**
   * Error reason code as string
   */
  reason: OAuthErrorReason | undefined = undefined

  constructor(
    message: string,
    reason?: OAuthErrorReason
  ) {
    super(message)

    // necessary to assure "instanceof" checks
    Object.setPrototypeOf(this, OAuthError.prototype)

    this.reason = reason

    if (typeof (Error as unknown as ErrorWithCaptureStackTrace).captureStackTrace === 'function') {
      (Error as unknown as ErrorWithCaptureStackTrace).captureStackTrace(this, OAuthError)
    } else {
      this.stack = new Error(message).stack
    }
  }
}

export default OAuthError
