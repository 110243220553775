import without from 'lodash/without'

export type SubscriptionHandler<Payload extends object | undefined> = (
  channelName: string, payload?: Payload
) => void | Promise<void>;
export type Subscriptions<Payload extends object | undefined> = SubscriptionHandler<Payload>[];
export type Channels = Record<string, Subscriptions<object | undefined>>;

/**
 * Publish/Subscription pattern message broker.
 */
export class MessageBroker {
  _observables: Channels = {}

  constructor() {
    this._observables = {}
  }

  /**
   * Subscribe a function {@link handler} to the passed
   * message channel {@link channelName}.
   * @param channelName Name of the channel to subscribe to
   * @param handler Subscription handler function should be registered
   */
  subscribe<Payload extends object | undefined>(
    channelName: string,
    handler: SubscriptionHandler<Payload>
  ) {
    this.unsubscribe(channelName, handler)
    this._observables[channelName].push(handler as SubscriptionHandler<object | undefined>)
  }

  /**
   * Unsubscribe a function {@link handler} from the passed
   * message channel {@link channelName}.
   * @param channelName Name of the channel to unsubscribe from
   * @param handler Subscription handler function that has been subscribed at the channel
   */
  unsubscribe<Payload extends object | undefined>(
    channelName: string,
    handler: SubscriptionHandler<Payload>
  ) {
    if (!this._observables[channelName]) this._observables[channelName] = []

    this._observables[channelName] = without(
      this._observables[channelName], handler
    ) as Subscriptions<object | undefined>
  }

  /**
   * Remove all subscribers to the passed message channel {@link channelName}.
   * @param channelName Name of the channel to delete all subscriptions from
   */
  unsubscribeAll(channelName: string) {
    delete this._observables[channelName]
  }

  /**
   * Send a message to the channel without waiting for the
   * responses of the handlers.
   * @param channelName Name of the channel to send a message to
   * @param payload The message data
   */
  message<Payload extends object>(channelName: string, payload?: Payload): void {
    void this.blockingMessage(channelName, payload)
  }

  /**
   * Send a message to the channel and wait for all
   * handlers to finish.
   * @param channelName Name of the channel to send a message to
   * @param payload The message data
   */
  async blockingMessage<Payload extends object>(
    channelName: string, payload?: Payload
  ): Promise<void | void[]> {
    if (!this._observables[channelName]) return Promise.resolve()

    return Promise.all(this._observables[channelName].map(
      (handler) => handler(channelName, payload)
    ))
  }

  /**
   * For testing purposes: returns the channel array for the
   * passed {@link channelName}
   * @param channelName Name of the channel array to return
   */
  getChannel<Payload extends object>(channelName: string): Subscriptions<Payload> {
    return this._observables[channelName] || []
  }
}

// Singleton instance
const singleton = new MessageBroker()
export default singleton
