import { Storage, StorageKey, StorageOptions } from '../Types'

/**
 * Attention: Don't use this class in production!
 */
class DefaultStorage implements Storage<StorageOptions> {
  options: StorageOptions = {}

  constructor(options: StorageOptions = {}) {
    this.options = options
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async get(_key: StorageKey): Promise<string | undefined> {
    throw new Error('Not implemented')
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async set(_key: StorageKey, _value: string | undefined): Promise<boolean> {
    throw new Error('Not implemented')
  }
}

export default DefaultStorage
