/**
 * Validate passed in value if it is a valid date object.
 * We use our own implementation to prevent lodash
 * boilerplate overhead.
 */
function isValidDate(value: unknown): value is Date {
  return !!value
    && Object.prototype.toString.call(value) === '[object Date]'
    && !isNaN((value as Date).getTime())
}

export default isValidDate
