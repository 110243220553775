/**
 * Check if a value is a valid array.
 * @param value The value to check.
 * @returns True if the value is an array, false otherwise.
 */
function isValidArray<T>(value: unknown): value is Array<T> {
  return  Array.isArray(value)
}

export default isValidArray
