import compact from 'lodash/compact'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import Config from '../Config'

export default function debugLog(value?: unknown, prefix = '[OAuthKit]') {
  if (!Config.get().debug) return

  const v = compact(isArray(value) ? value : [value])

  if (v.length === 0) return

  const debugHandler = Config.get().debugHandler

  if (debugHandler && isFunction(debugHandler)) {
    debugHandler(`${prefix}: ${joinOutput(v)}`)
  } else {
    console.debug(`${prefix}: ${joinOutput(v)}`)
  }
}

function joinOutput(value: unknown[]): string {
  return value.map((item) => JSON.stringify(item)).join(' ;;; ')
}
