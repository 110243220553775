import OAuthKit from '../main'
import StorageManager from '../StorageManager'

import type { Operation } from '@apollo/client'

/**
 * ApolloLink request handler to test for auth state
 * and inject token to calls.
 */
async function ApolloRequestHandler(
  operation?: Operation
): Promise<Operation | undefined>  {
  if (await OAuthKit.isTokenLocalValid() === false) {
    OAuthKit.notifyAuthRequiredListeners()
    return undefined
  }

  await OAuthKit.setLastActivity()

  operation?.setContext({
    headers: {
      'Authorization': await StorageManager.getTokenWithType()
    }
  })

  return operation
}

export default ApolloRequestHandler
