
import isNumber from './isNumber'

/**
 * Checks if a passed value is a valid number
 *
 * ```js
 * import isValidNumber from '@vayapin/isValidNumber'
 *
 * isValidNumber('1') // false
 * isValidNumber('test') // false
 * isValidNumber(NaN) // false
 * isValidNumber(1) // true
 * ```
 */
function isValidNumber(value: unknown): value is number {
  return isNumber(value) && !isNaN(value) && value != (value + 1) && value !== Infinity
}

export default isValidNumber
