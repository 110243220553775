/**
 * Helper methods to build complex OAuth urls
 */

import map from 'lodash/map'

import Config from '../Config'
import OAuthError from '../errors/OAuthError'
import PkceHelper from '../PkceHelper'
import isPresent from './isPresent'

import type { NavigateSignInOptions } from '../Types'

export type SignInParams = {
  client_id: string;
  response_type: string;
  code_challenge: string;
  code_challenge_method: string;
  scope: string;
  redirect_uri: string;
  auto_sign_in: boolean;
  client_secret?: string;
} & Record<string, unknown>

export type FetchTokenParams = {
  grant_type: 'authorization_code';
  client_id: string;
  code_verifier: string | undefined;
  redirect_uri: string;
  code: string;
  client_secret?: string;
}

export type FetchRefreshTokenParams = {
  grant_type: 'refresh_token';
  client_id: string;
  refresh_token: string;
  client_secret?: string;
}

/**
 * Builds the OAuth authorization code PKCE data object. It'll be used to create the
 * params that will be transfered to the OAuth sign in page.
 *
 * Additionally, it'll check for necessary data and will throw errors if something is missing.
 *
 * The challenge verifier will be persisted to the local storage to use it later on
 * inside the oauth callback.
 *
 * @return OAuth authorization PKCE data
 */
export async function buildOAuthSignInParams(
  options: NavigateSignInOptions | undefined = {}
): Promise<SignInParams> {
  const config = Config.get()
  const { clientId, clientSecret, clientScope, redirectUri } = config
  const additionalParams = options.additionalParams ?? {}
  const debug = options.debug === true
  const autoSignIn = options.autoSignIn === true

  if (!isPresent(clientId)) throw new OAuthError('Config.get().clientId is missing')
  if (!isPresent(clientScope)) throw new OAuthError('Config.get().clientScope is missing')
  if (!isPresent(redirectUri)) throw new OAuthError('Config.get().redirectUri is missing')

  const { challenge } = await PkceHelper.generateAndPersistCredentials({ debug })

  const params = {
    ...additionalParams,
    client_id: clientId,
    response_type: 'code',
    code_challenge: challenge,
    code_challenge_method: 'S256',
    scope: clientScope,
    redirect_uri: encodeURI(redirectUri),
    auto_sign_in: autoSignIn,
  }

  // @ts-ignore
  if (isPresent(clientSecret)) params.client_secret = clientSecret

  return params
}

/**
 * Creates the URL params string to be sent to the sign in page for OAuth. To get the data
 * it utilizes #buildAuthSignInAuthorizationCodePkceData().
 *
 * @return The params string
 */
async function buildMergedOAuthSignInParams(
  options: NavigateSignInOptions | undefined = {}
): Promise<string> {
  const params = await buildOAuthSignInParams(options)

  return map(params, (v, k) => `${k}=${v as string}`).join('&')
}

/**
 * Builds the id server URI to send the user to authorize.
 * @return Full URI including all params.
 */
async function buildOAuthSignInUri(
  options: NavigateSignInOptions | undefined = {}
): Promise<string> {
  const { idHost, idSignInPath } = Config.get()

  if (!isPresent(idHost)) throw new OAuthError('Config.get().idHost is missing')
  if (!isPresent(idSignInPath)) {
    throw new OAuthError('Config.get().idSignInPath is missing')
  }

  const idSignInUrl = [
    idHost.replace(/\/$/, ''),
    idSignInPath
  ].join('')

  return [
    idSignInUrl,
    await buildMergedOAuthSignInParams(options)
  ].join('?')
}

/**
 * Builds the id server URI to send the user to authorize and sign up.
 * @return Full URI including all params.
 */
async function buildOAuthSignUpUri(
  options: NavigateSignInOptions | undefined = {}
): Promise<string> {
  const { idHost, idSignUpPath } = Config.get()

  if (!isPresent(idHost)) throw new OAuthError('Config.get().idHost is missing')
  if (!isPresent(idSignUpPath)) {
    throw new OAuthError('Config.get().idSignUpPath is missing')
  }

  const idSignUpUrl = [
    idHost.replace(/\/$/, ''),
    idSignUpPath
  ].join('')

  return [
    idSignUpUrl,
    await buildMergedOAuthSignInParams(options)
  ].join('?')
}

/**
 * Builds the data object for the request made to the id server based on the
 * passed code including the PKCE code verifier.
 * @return The data to be submitted to the id server
 */
async function buildOAuthFetchTokenParams(
  /**
   * The id server authorization code.
   */
  code: string
): Promise<FetchTokenParams> {
  const config = Config.get()
  const { clientId, clientSecret, redirectUri } = config

  if (!isPresent(clientId)) {
    throw new OAuthError(
      'Config.get().clientId is missing',
      'INCOMPLETE_CONFIGURATION'
    )
  }
  if (!isPresent(redirectUri)) {
    throw new OAuthError(
      'Config.get().redirectUri is missing',
      'INCOMPLETE_CONFIGURATION'
    )
  }
  if (!isPresent(code)) {
    throw new OAuthError(
      'Authorization code is missing',
      'AUTHORIZATION_CODE_MISSING'
    )
  }

  const params: FetchTokenParams = {
    grant_type: 'authorization_code',
    client_id: clientId,
    code_verifier: await PkceHelper.getStoredCodeVerifier(),
    redirect_uri: encodeURI(redirectUri),
    code
  }

  if (isPresent(clientSecret)) params.client_secret = clientSecret

  return params
}

/**
 * Builds the data object for the request made to the id server based
 * to utilize the refresh token to get a new JWT.
 * @return The data to be submitted to the id server
 */
async function buildOAuthRefreshTokenParams(
  refreshToken: string
): Promise<FetchRefreshTokenParams> {
  const config = Config.get()
  const { clientId, clientSecret } = config

  if (!isPresent(clientId)) {
    throw new OAuthError('Config.get().clientId is missing', 'INCOMPLETE_CONFIGURATION')
  }
  if (!isPresent(refreshToken)) {
    throw new OAuthError('Refresh token is missing', 'REFRESH_TOKEN_MISSING')
  }

  const params: FetchRefreshTokenParams = {
    grant_type: 'refresh_token',
    client_id: clientId,
    refresh_token: refreshToken
  }

  if (isPresent(clientSecret)) params.client_secret = clientSecret

  return Promise.resolve(params)
}

/**
 * Builds the full url to sign out at id service
 */
export function buildSignOutUri() {
  const { idHost, idSignOutPath } = Config.get()

  if (!isPresent(idHost)) throw new OAuthError('Config.get().idHost is missing')
  if (!isPresent(idSignOutPath)) {
    throw new OAuthError('Config.get().idSignOutPath is missing')
  }

  return [
    idHost.replace(/\/$/, ''),
    idSignOutPath
  ].join('')
}

/**
 * UrlBuilder
 */
const UrlBuilder = {
  // sign in / pkce
  buildOAuthSignInParams,
  buildMergedOAuthSignInParams,
  buildOAuthSignInUri,
  buildOAuthSignUpUri,

  // token
  buildOAuthFetchTokenParams,

  // token refresh
  buildOAuthRefreshTokenParams,

  // sign out
  buildSignOutUri,
}

export default UrlBuilder
