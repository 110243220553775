/**
 * Api implementation
 */

import type { OAuthTokenData, OAuthTokenInfoData, OAuthUserData } from './Types'
import axios, {
  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse,
} from 'axios'
import has from 'lodash/has'
import includes from 'lodash/includes'
import isNaN from 'lodash/isNaN'
import isNumber from 'lodash/isNumber'
import isObject from 'lodash/isObject'
import omit from 'lodash/omit'
import Config from './Config'
import OAuthError from './errors/OAuthError'
import StorageManager from './StorageManager'
import { isPresent, UrlBuilder } from './utils'
import handleError from './utils/handleError'
import debugLog from './utils/debugLog'

let _instance: AxiosInstance | undefined = undefined

export interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
  /**
   * Additional internal information
   */
  meta?: Record<string, unknown>;
}

/**
 * Additional API call options
 */
export interface CallOptions {
  /**
   * append auth configuration
   */
  withOAuthConfig?: boolean;
}

/**
 * Build and return axios instance
 * @return Axios instance
 */
export function getInstance(): AxiosInstance {
  if (!_instance) _instance = axios.create({ baseURL: getIdApiHost() })
  return _instance
}

/**
 * Load host from config
 * @return URL
 */
export function getIdApiHost(): string {
  return Config.get().idApiHost || Config.get().idHost
}

/**
 * General preparations for an axios config
 * to be used for a request.
 * @returns Updated/new axios config object
 */
export function prepareAxiosConfig(
  axiosConfig: ExtendedAxiosRequestConfig = {}
): ExtendedAxiosRequestConfig {
  axiosConfig = { ...axiosConfig }

  // baseURL
  if (!axiosConfig.baseURL && !/^http(s)?:\/\/.*/.test(axiosConfig.url || '')) {
    axiosConfig.baseURL = getIdApiHost()
  }

  // basic headers
  if (!isObject(axiosConfig.headers)) axiosConfig.headers = {}
  if (!axiosConfig.headers['Accept']) axiosConfig.headers['Accept'] = 'application/json'

  // prevent redirect following
  if (!axiosConfig.maxRedirects) axiosConfig.maxRedirects = 0

  // ensure data
  if (!axiosConfig.data) axiosConfig.data = {}

  // return adjusted config
  return axiosConfig
}

/**
 * Appends the axios config with auth information. If the JWT is not
 * valid anymore, it'll throw an {OAuthError}.
 * @returns {Object} Updated/new axios config object
 */
export async function prepareAxiosConfigAuthorized(
  axiosConfig: ExtendedAxiosRequestConfig = {}
): Promise<ExtendedAxiosRequestConfig> {
  axiosConfig = { ...axiosConfig }

  // Append JWT as Authorization header
  if (!isObject(axiosConfig.headers)) axiosConfig.headers = {}
  axiosConfig.headers.Authorization = await StorageManager.getTokenWithType()

  // Append account information to distinguish between multiple
  // existing accounts
  const userData = await StorageManager.getUserData()
  if (isObject(userData) && isObject(userData.currentAccount)) {
    axiosConfig.headers.VayapinUserCurrentAccount = userData.currentAccount.id
  }

  // Meta information for VayaPin specific error handling.
  // The error response does not contain any information about the type
  // of the request. That's why we're adding this information.
  if (!isObject(axiosConfig.meta)) axiosConfig.meta = {}
  axiosConfig.meta.vayapinOAuth = true

  // return adjusted config
  return axiosConfig
}

/**
 * Remove body for GET requests
 */
export function prepareAxiosConfigBody(
  axiosConfig: ExtendedAxiosRequestConfig = {}): ExtendedAxiosRequestConfig {
  if (axiosConfig.method !== 'GET' && axiosConfig.method !== 'get') return axiosConfig

  return omit(axiosConfig, 'body', 'data')
}

/**
 * Api call
 */
export async function call<T>(
  axiosConfig: ExtendedAxiosRequestConfig = {},
  options: CallOptions = {}
): Promise<AxiosResponse<T> | AxiosError> {
  const instance = getInstance()

  axiosConfig = prepareAxiosConfig(axiosConfig)
  axiosConfig = prepareAxiosConfigBody(axiosConfig)

  if (options.withOAuthConfig === true) {
    axiosConfig = await prepareAxiosConfigAuthorized(axiosConfig)
    await StorageManager.setLastActivity()
  }

  try {
    return await instance.request<T>(axiosConfig)
  } catch (e) {
    const err = e as AxiosError

    if (axiosConfig.meta && axiosConfig.meta.vayapinOAuth === true
        && err?.response?.status
        && includes([401, '401'], err.response.status)) {
      return err
    }

    handleError(err)
    return err
  }
}

/**
 * @private
 * Execute API token fetch call and handle response
 */
export async function fetchAccessTokenWithParams(
  params: Record<string, unknown>,
): Promise<OAuthTokenData | OAuthError | Error> {
  debugLog(['fetchAccessTokenWithParams', params])
  const { idApiHost, idHost, idApiOAuthTokenPath } = Config.get()
  const host = idApiHost || idHost

  if (!isPresent(host)) {
    return handleError(new OAuthError(
      'Config.get().idApiHost or Config.get().idHost is missing',
      'INCOMPLETE_CONFIGURATION'
    ))
  }

  if (!isPresent(idApiOAuthTokenPath)) {
    return handleError(new OAuthError(
      'Config.get().idApiOAuthTokenPath is missing',
      'INCOMPLETE_CONFIGURATION'
    ))
  }

  let result: AxiosError<unknown> | AxiosResponse<OAuthTokenData> | undefined = undefined
  try {
    result = await Api.call<OAuthTokenData>({
      method: 'post', url: idApiOAuthTokenPath, data: params
    })
  } catch (e) {
    const err = e as AxiosError
    const res = (err?.response ?? {}) as AxiosResponse<OAuthTokenData>
    const _data = res.data || {}

    if (_data.error) {
      return handleError(new OAuthError(_data.error_description ?? _data.error))
    }

    return handleError(e as Error)
  }

  // Handle error
  if (result instanceof Error) {
    const err = result
    const res = (err?.response ?? {}) as AxiosResponse<OAuthTokenData>
    const _data = res.data || {}
    return handleError(new OAuthError(_data.error_description ?? _data.error ?? ''))
  }

  // Handle response
  if (!isObject(result) || !isObject(result.data)) {
    return handleError(new OAuthError(
      'OAuth authorization code response is not valid',
      'OAUTH_RESPONSE_INVALID'
    ))
  }

  if (!isPresent(result.data.access_token)) {
    return handleError(new OAuthError(
      'OAuth authorization code response - access_token is invalid or missing',
      'OAUTH_RESPONSE_ACCESS_TOKEN_INVALID'
    ))
  }

  if (!isPresent(result.data.token_type)) {
    return handleError(new OAuthError(
      'OAuth authorization code response - token_type is invalid or missing',
      'OAUTH_RESPONSE_TOKEN_TYPE_INVALID'
    ))
  }

  if (!isNumber(result.data.expires_in) || isNaN(result.data.expires_in)) {
    return handleError(new OAuthError(
      'OAuth authorization code response - expires_in is invalid or missing',
      'OAUTH_RESPONSE_EXPIRES_IN_INVALID'
    ))
  }

  if (!isPresent(result.data.scope)) {
    return handleError(new OAuthError(
      'OAuth authorization code response - scope is invalid or missing',
      'OAUTH_RESPONSE_CREATED_AT_INVALID'
    ))
  }

  if (!isNumber(result.data.created_at) || isNaN(result.data.created_at)) {
    return handleError(new OAuthError(
      'OAuth authorization code response - created_at is invalid or missing',
      'OAUTH_RESPONSE_SCOPE_INVALID'
    ))
  }

  return result.data
}

/**
 * Fetches the OAuth JWT from the id service for the passed authorization code.
 */
export async function fetchAccessToken(
  /**
   * Authorization code
   */
  code: string
): Promise<OAuthTokenData | OAuthError | Error> {
  debugLog(['fetchAccessToken', code])

  if (!isPresent(code)) {
    return handleError(new OAuthError(
      'Authorization code is missing',
      'AUTHORIZATION_CODE_MISSING'
    ))
  }

  // Build url params based on token. This will
  // generate a PKCE verifier and challenge.
  const params = await UrlBuilder.buildOAuthFetchTokenParams(code)

  // fetch
  return await fetchAccessTokenWithParams(
    params
  )
}

/**
 * Fetches the OAuth JWT from the id service for the passed authorization code.
 */
export async function refetchAccessToken(): Promise<OAuthTokenData | false | OAuthError | Error> {
  debugLog(['refetchAccessToken'])

  const refreshToken = await StorageManager.getRefreshToken()

  if (!isPresent(refreshToken)) {
    return false
  }

  // Build url params based on token. This will
  // generate a PKCE verifier and challenge.
  const params = await UrlBuilder.buildOAuthRefreshTokenParams(refreshToken)

  // fetch
  return await fetchAccessTokenWithParams(
    params
  )
}

/**
 * Fetches the user information from id server.
 */
export async function fetchUserInfo(): Promise<OAuthUserData | OAuthError | Error> {
  debugLog(['OAuthKit.fetchUserInfo'])

  const { idHost, idApiHost, idApiOAuthMePath } = Config.get()
  const url = idApiHost || idHost

  if (!isPresent(url)) {
    return handleError(new OAuthError(
      'Config.get().idApiHost or Config.get().idHost is missing',
      'INCOMPLETE_CONFIGURATION'
    ))
  }

  if (!isPresent(idApiOAuthMePath)) {
    return handleError(new OAuthError(
      'Config.get().idApiOAuthMePath is missing',
      'INCOMPLETE_CONFIGURATION'
    ))
  }

  try {
    let result = await Api.call<OAuthUserData>({
      method: 'get',
      url: idApiOAuthMePath,
      headers:  { Authorization: await StorageManager.getTokenWithType() }
    }, { withOAuthConfig: true })

    result = result as AxiosError

    if (includes([401, '401'], result?.response?.status)) {
      return handleError(new OAuthError(
        'Token is not valid (anymore?)',
        'OAUTH_RESPONSE_TOKEN_INVALID'
      ))
    }

    result = result as unknown as AxiosResponse<OAuthUserData>

    if (!isObject(result.data) || !has(result.data, 'email')) {
      return handleError(new OAuthError(
        'failed to load user information',
        'OAUTH_RESPONSE_INVALID'
      ))
    }

    return result.data
  } catch (e) {
    const err = e as AxiosError
    if (!err?.response?.status) {
      return handleError(e as Error)
    } else if (includes([401, '401'], err.response.status)) {
      return handleError(new OAuthError(
        'Token is not valid (anymore?)',
        'OAUTH_RESPONSE_TOKEN_INVALID'
      ))
    }

    throw e
  }
}

/**
 * Fetch token info from api
 */
export async function fetchTokenInfo(): Promise<OAuthTokenInfoData | OAuthError | Error> {
  debugLog(['fetchTokenInfo'])

  const { idHost, idApiHost, idApiOAuthTokenInfoPath } = Config.get()
  const url = idApiHost || idHost

  if (!isPresent(url)) {
    return handleError(new OAuthError(
      'Config.get().idApiHost or Config.get().idHost is missing',
      'INCOMPLETE_CONFIGURATION'
    ))
  }

  if (!isPresent(idApiOAuthTokenInfoPath)) {
    return handleError(new OAuthError(
      'Config.get().idApiOAuthTokenInfoPath is missing',
      'INCOMPLETE_CONFIGURATION'
    ))
  }

  try {
    let result = await Api.call<OAuthTokenInfoData>({
      method: 'get',
      url: idApiOAuthTokenInfoPath,
      headers:  { Authorization: await StorageManager.getTokenWithType() }
    }, { withOAuthConfig: true })

    result = result as AxiosError

    if (includes([401, '401'], result?.response?.status)) {
      return handleError(new OAuthError(
        'Token is not valid (anymore?)',
        'OAUTH_RESPONSE_TOKEN_INVALID'
      ))
    }

    if (!result?.status || !includes([200, '200', 201, '201'], result?.status)) {
      return handleError(new OAuthError(
        'response_invalid',
        'OAUTH_RESPONSE_INVALID'
      ))
    }

    result = result as unknown as AxiosResponse<OAuthTokenInfoData>

    return result.data
  } catch (e) {
    const err = e as AxiosError
    if (!err?.response?.status) {
      return handleError(e as Error)
    } else if (includes([401, '401'], err.response.status)) {
      return handleError(new OAuthError(
        'Token is not valid (anymore?)',
        'OAUTH_RESPONSE_TOKEN_INVALID'
      ))
    }

    return handleError(e as Error)
  }
}

/**
 * Delete axios instance
 */
export function reset() {
  _instance = undefined
}

/**
 * Api
 */
const Api = {
  getInstance,
  getIdApiHost,
  prepareAxiosConfig,
  prepareAxiosConfigAuthorized,
  prepareAxiosConfigBody,
  call,
  fetchAccessTokenWithParams,
  fetchAccessToken,
  refetchAccessToken,
  fetchUserInfo,
  fetchTokenInfo,
  reset,
}

export default Api
